@import "../../../baseTheme/settings";


.shop-by-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    > div {
        flex-basis: 15%;
        margin-bottom: 32px;

        &:last-child {
            margin-right: 0;
        }

        @include media-breakpoint-down(md) {
            flex-basis: 30%;
            margin-bottom: 16px;
        }
    }
}

.category-title {
    a {
        display: block;
    }

    picture {
        overflow: hidden;
        margin-bottom: 24px;
        display: block;

        img {
            width: 100%;
            border-top-right-radius: 24px;
            border-bottom-left-radius: 24px;
        }

        @include media-breakpoint-down(md) {
            margin-bottom: 12px;
        }
    }

    .category-name {
        @include primaryFont(400);
        text-align: center;

        @include media-breakpoint-down(md) {
            font-size: 14px;
            line-height: 18px;
            margin-bottom: 0;
        }
    }
}